window.modalRegionHandler = () => {
  document.addEventListener('DOMContentLoaded', () => {
    initSelectRegion();
    choseRegion();
  });
  const regionModal = document.querySelector('.jsNavRegionModal');
  const regionButton = document.querySelector('.jsOpenRegionModalBtn');

  const updateRegionRequest = (modal, userRegion) => {
    const url = '/myspacestor/update-region/';
    const data = {"user_region": userRegion};

    $.ajax({
      method: 'POST',
      url,
      data,
      success: (data) => {
        const dataSuccess = data.success;
        const dataUrl = data.url;

        if (dataSuccess) {
          setCookie('user_region', userRegion);
          if (modal) {
            modal.classList.remove('active');
          }
          regionModal.classList.add('navigation__hide');
          regionButton.classList.remove('navigation__countryBtn--active')
        }

        dataUrl
          ? (window.location.href = dataUrl)
          : location.reload();
      },
      error: (data) => {
        console.log('failure ' + data)
      }, headers: {
        'X-CSRFToken': getCookie('csrftoken')
      }
    });
  };

  const initSelectRegion = () => {
    const regionCookie = getCookie('user_region');
    const modal = document.querySelector('#modal-region');
    const pricingLocation = window.location.pathname.startsWith('/pricing');

    // Show modal region when page run
    if (!pricingLocation && regionCookie === "") {
      modal?.classList.add('active');
    }
  };

  const choseRegion = () => {
    window.addEventListener('click', (e) => {
      e.stopImmediatePropagation();
      const closeButton = e.target.closest('.jsModalRegionClose');
      const input = e.target.closest('.jsModalInput');
      const listItem = input?.closest('.jsModalListItem');

      if (Boolean(listItem)) {
        const label = listItem.querySelector('.jsModalLabel');
        const modal = label.closest('.jsModal');
        const regionId = label.getAttribute('data-id');

        updateRegionRequest(modal, regionId);
      }

      if (Boolean(closeButton)) {
        const modal = closeButton.closest('.jsModal');
        const regionCookie = getCookie('user_region');
        const pricingLocation = window.location.pathname.startsWith('/pricing');

        if (regionCookie === undefined || !pricingLocation) {
          const regionListContainer = modal.querySelector('.jsModalList');
          const firstListItem = regionListContainer.querySelectorAll('.jsModalListItem')[0];
          const regionId = firstListItem.querySelector('.jsModalLabel').getAttribute('data-id');

          updateRegionRequest(modal, regionId);
        }
      }
    });
  };
}
